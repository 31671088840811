export const PREFIX_USER = 'user-';

// 通过当前树节点的id，找到当前节点的所有父级节点id，并将lsit返回
export const findParentIdList = (data, id) => {
    let result = [];
    const findParent = (id, arr) => {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].id === id) {
                return true;
            } else if (arr[i].children) {
                if (findParent(id, arr[i].children)) {
                    result.unshift(arr[i].id);
                    return true;
                }
            }
        }
        return false;
    };
    findParent(id, data);
    return result;
};

//合并userList到children
export const formatTreeData = (treeArr, disabledUserList = []) => {
    treeArr.forEach((item) => {
        if (item.children?.length && item.userList?.length) {
            item.children = item.children?.filter((child) => child?.userList?.length || child?.children?.length) || []; //过滤掉下面无人的组织
            item.type = 'org'
            item.userList?.reverse()?.forEach((userItem) => {
                userItem.type = "user";
                userItem.slots = {
                    icon: "userIcon",
                };
                userItem.parentId = item.id;
                userItem.orgName = userItem.name;
                userItem.enName = userItem.userName;
                userItem.disableCheckbox = disabledUserList?.map(item => String(item))?.includes(String(userItem.id));
                userItem.id = PREFIX_USER + userItem.id;
                item.children.unshift(userItem);
            });
            delete item.userList;
        } else if(item.children?.length && !item.userList?.length){
            item.type = 'org'
            delete item.userList;
        } else if(!item.children?.length && item.userList?.length) {
            item.type = 'org'
            item.children = item.userList.reduce((prev, userItem) => {
                userItem.type = "user";
                userItem.slots = {
                    icon: "userIcon",
                };
                userItem.parentId = item.id;
                userItem.orgName = userItem.name;
                userItem.enName = userItem.userName;
                userItem.disableCheckbox = disabledUserList?.map(item => String(item))?.includes(String(userItem.id));
                userItem.id = PREFIX_USER + userItem.id;
                prev.push(userItem);
                return prev;
            }, []);
            delete item.userList;
        } else if(!item.children?.length && !item.userList?.length && item.type != 'user'){
            item.type = 'org'
            item.children = [];
            delete item.userList;
        }

        if (item?.children?.length) {
            formatTreeData(item.children, disabledUserList);
        }
    });
    return treeArr;
};

export const getNodeById = (id, treeData) => {
    // 定义一个递归函数来遍历树数据
    function findNodeById(node, id) {
        // 如果当前节点的 id 匹配，则返回当前节点
        if (node.id === id) {
            return node;
        }
        // 如果当前节点有子节点，则继续递归查找
        if (node.children && node.children.length > 0) {
            for (let i = 0; i < node.children.length; i++) {
                const result = findNodeById(node.children[i], id);
                // 如果在子节点中找到匹配的节点，则返回结果
                if (result) {
                    return result;
                }
            }
        }
        // 如果没有找到匹配的节点，则返回 null
        return null;
    }

    // 遍历整个树数据
    for (let i = 0; i < treeData.length; i++) {
        const result = findNodeById(treeData[i], id);
        // 如果在根节点中找到匹配的节点，则返回结果
        if (result) {
            return result;
        }
    }

    // 如果整个树数据中都没有找到匹配的节点，则返回 null
    return null;
}

// 根据id获取当前id下的所有叶子节点的数量
export function getLeafNodeCountById(id, treeData) {
    const currentNode = getNodeById(id, treeData);
    let leafNodeCount = 0;
    function countLeafNodes(node) {
      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => {
          countLeafNodes(child);
        });
      } else {
        if(String(node.id).includes(PREFIX_USER)){
            leafNodeCount++;
        }
      }
    }
    if (currentNode) {
      countLeafNodes(currentNode);
    }
    return leafNodeCount;
}
// 根据id获取当前id下的所有叶子节点的id列表
export function getLeafNodeListById(id, treeData) {
    const currentNode = getNodeById(id, treeData);
    let leafNodeIdList = [];
    function countLeafNodes(node) {
      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => {
          countLeafNodes(child);
        });
      } else {
        leafNodeIdList.push(node.id);
      }
    }
    if (currentNode) {
      countLeafNodes(currentNode);
    }
    return leafNodeIdList;
}

export const getCheckedUserList = (checkedUser = [], treeData) => {
    if (!checkedUser?.length || !treeData?.length) {
        return [];
    }
    const list = []
    checkedUser.forEach(item => {
        let userItem = getNodeById(item, treeData)
        if(userItem){
            list.push(userItem)
        }
    })
    return list
}

export const colorArr = [
    'rgba(47, 210, 220, 0.7)',
    'rgba(5, 166, 176, 0.7)',
    'rgba(47, 106, 220, 0.7)',
    'rgba(93, 146, 249, 0.7)',
    'rgba(51, 138, 239, 0.7)',
    'rgba(12, 87, 174, 0.7)',
    'rgba(34, 206, 123, 0.7)',
    'rgba(12, 168, 93, 0.7)',
    'rgba(96, 193, 20, 0.7)',
    'rgba(106, 154, 5, 0.7)',
    'rgba(178, 120, 6, 0.7)',
    'rgba(222, 149, 7, 0.7)',
    'rgba(134, 47, 220, 0.7)',
    'rgba(175, 101, 220, 0.7)',
    'rgba(206, 47, 220, 0.7)',
    'rgba(101, 14, 154, 0.7)',
    'rgba(220, 47, 140, 0.7)',
    'rgba(180, 11, 102, 0.7)',
    'rgba(220, 47, 68, 0.7)',
    'rgba(164, 5, 24, 0.7)',
  ]
  export function charToUnicode(str) {
    let temp;
    let r = 0;
    let reg = /[a-zA-Z]/g;
    let i = 0
    for (let val of str) {
      temp = val.codePointAt(0).toString(16);
  
      while (temp.length < 4)
        temp = '0' + temp;
  
  
      r += Number(temp.replace(reg, ""));
  
  
    }
    i = r % 20
    return i;
  }